<template>
  <div>
    <b-sidebar
      id="structure-resource-link-sidebar-right"
      class="structure-sidebar-link-resource-modal"
      right
      bg-variant="white"
      width="407px"
      v-model="isOpen"
      @hidden="closeModal"
    >
      <template>
        <div>
          <div class="text-block">
            <div class="title">{{ $t('StructurePage.LinkResource') }}</div>
            <div class="subtitle">
              {{ $t('StructurePage.SubTitleLinkModal') }}
            </div>
          </div>
          <div class="search-block">
            <div class="search-input">
              <div class="div-custom-input input__structures">
                <div class="icon-block">
                  <SearchIcon class="search-icon" />
                </div>
                <b-form-tags
                  class="input-search-structures"
                  ref="formTagStructures"
                  v-model="filter.textFilter"
                  duplicate-tag-text=""
                  :placeholder="
                    filter.textFilter.length === 0 ? $t('SearchPlaceholderDefault') : ''
                  "
                  @input="(e) => updateFilter(e)"
                  remove-on-delete
                />
              </div>
            </div>
          </div>
          <div class="action-block">
            <div class="select-all">
              <div
                :class="['checkbox-container', allResourceChecked ? 'checked' : '']"
                @click="checkAllResource()"
              >
                <span class="icon-checkbox"><CheckIcon /></span>
              </div>
              <span class="text">
                {{ $t('SelectAll') }}
              </span>
            </div>
            <div class="function-filter">
              <span>{{ $t('StructurePage.Type') }}</span>
              <div
                id="filter-resource-select-link"
                class="filter"
                tabindex="0"
              >
                <span>{{ $t(selectedFilter) }}</span>
                <ArrowDown class="arrow-down" />
              </div>
            </div>
            <Popover
              :items="typeList"
              target="filter-resource-select-link"
              placement="bottom"
              custom-position="action-button"
              triggers="focus"
            />
          </div>
          <div
            class="resource-block"
            v-if="forceRenderLinkResource"
          >
            <div
              v-if="filteredList.length === 0"
              class="block-no-results"
            >
              <div class="no-results">
                <SearchIcon />
                <p class="text">{{ $t('NoResultsFound') }}</p>
              </div>
            </div>
            <div v-else>
              <div
                v-for="resource in filteredList"
                :key="resource.guid"
              >
                <div class="user-info-block">
                  <div class="check-resource">
                    <div
                      :class="[
                        'checkbox-container __no-action-default',
                        resource.checked ? 'checked' : ''
                      ]"
                      @click="toggleCheckbox(resource)"
                    >
                      <span class="icon-checkbox __no-action-default"><CheckIcon /></span>
                    </div>
                  </div>
                  <div class="text-block">
                    <div class="resource-name">
                      {{ resource.name }}
                    </div>
                    <div class="resource-code">{{ resource.code }}</div>
                  </div>
                  <div
                    v-if="resource.type"
                    class="roles-block"
                  >
                    <div class="role-tag">
                      {{ resource.type }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <b-col
          md="12"
          class="footer-sidebar"
        >
          <div class="d-flex">
            <b-button
              class="btn-cancel btn-footer"
              @click="closeModal()"
            >
              {{ $t('RefuseStatus.Cancel') }}
            </b-button>
            <b-button
              class="btn-save btn-footer"
              @click="onLinkResource()"
            >
              {{ $t('IntegrationPage.Link') }}
            </b-button>
          </div>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import { BSidebar, BCol, BButton, BFormInput, BFormTags } from 'bootstrap-vue';
  import { mapState, mapMutations } from 'vuex';
  import Popover from '@core/layouts/layout-horizontal/components/Menu/Popover.vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';

  export default {
    components: {
      BSidebar,
      BCol,
      BButton,
      BFormInput,
      BFormTags,
      ToastificationContent,
      Popover,
      ArrowDown: () => import('@core/assets/icons/arrow-down-orange.svg'),
      CheckIcon: () => import('@core/assets/icons/check-icon.svg'),
      SearchIcon: () => import('@core/assets/icons/search-icon.svg')
    },
    props: {
      device: {
        type: String,
        default: 'desktop'
      },
      currentId: {
        type: Number,
        default: null
      },
      prefix: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        forceRenderLinkResource: true,
        allResourceChecked: false,
        isOpen: false,
        filter: {
          textFilter: []
        },
        selectedFilter: 'DepartmentPage.All',
        filteredList: [],
        typeList: [
          {
            title: 'DepartmentPage.All',
            click: () => {
              this.selectedFilter = 'DepartmentPage.All';
            }
          },
          {
            title: 'StructurePage.Machine',
            click: () => {
              this.selectedFilter = 'StructurePage.Machine';
            }
          }
        ]
      };
    },
    mounted() {
      this.UPDATE_MODAL_LINK_RESOURCE_TOGGLE(false);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('structures', [
        'UPDATE_MODAL_LINK_RESOURCE_TOGGLE',
        'UPDATE_RESOURCE_TO_LINK_LIST',
        'UPDATE_RESOURCE_LIST'
      ]),
      closeModal() {
        this.UPDATE_MODAL_LINK_RESOURCE_TOGGLE(false);
      },
      updateFilterByFunction(func) {
        if (func === 'DepartmentPage.All') {
          this.filteredList = this.resourceToLink;
        } else {
          this.filteredList = this.resourceToLink.filter((resource) => resource.type);
        }
      },
      getAllFunctionsFilter() {
        // this.typeList = [
        //   {
        //     title: 'DepartmentPage.All',
        //     click: () => {
        //       this.selectedFilter = 'DepartmentPage.All';
        //     }
        //   },
        //   ...this.rolesList.reduce((acc, e) => {
        //     const existingFunction = acc.find((f) => f.title === e.name);
        //     if (!existingFunction) {
        //       acc.push({
        //         title: e.name,
        //         click: () => {
        //           this.selectedFilter = e.name;
        //         }
        //       });
        //     }
        //     return acc;
        //   }, [])
        // ];
      },
      toggleCheckbox(elem) {
        if (!elem.checked) {
          elem['checked'] = true;
        } else {
          elem['checked'] = false;
          this.allResourceChecked = false;
        }
        this.forceRenderLinkResourceList();
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      unCheckAllResource() {
        this.resourceToLink.map((e) => {
          e['checked'] = false;
        });
        this.allResourceChecked = false;
      },
      updateFilter(e) {
        this.updateFilterByFunction(this.selectedFilter);
        if (e.length === 0) return;

        const arr = this.filteredList;

        let filteredArray = [];
        e.forEach((filterValue) => {
          const regex = new RegExp(filterValue.toLowerCase(), 'i');
          const filteredItems = arr.filter((item) => {
            return regex.test(item.name.toLowerCase()) || regex.test(item.code);
          });
          filteredArray = filteredArray.concat(filteredItems);
        });

        this.filteredList = filteredArray;
      },
      checkAllResource() {
        this.filteredList.map((e) => {
          if (this.allResourceChecked) {
            e['checked'] = false;
          } else {
            e['checked'] = true;
          }
        });
        this.verifyAllChecked();
        this.forceRenderLinkResourceList();
      },
      verifyAllChecked() {
        this.filteredList.map((e) => {
          if (!e.checked) {
            this.allResourceChecked = false;
          } else {
            this.allResourceChecked = true;
          }
        });
      },
      forceRenderLinkResourceList() {
        this.forceRenderLinkResource = false;
        this.$nextTick().then(() => {
          this.forceRenderLinkResource = true;
        });
      },
      async getResourceToLink() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        await this.$http
          .get(`/api/${this.prefix}/myconfig/workcenter/${this.currentId}/resources/context`)
          .then((response) => {
            this.UPDATE_RESOURCE_TO_LINK_LIST(response.data.data.resources);
            this.updateFilterByFunction(this.selectedFilter);
            this.forceRenderLinkResourceList();
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          })
          .catch((error) => console.error(error));
      },
      async onLinkResource() {
        const hasChecked = this.filteredList.find((e) => e.checked);
        if (!hasChecked) return;

        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        const checkedResources = this.resourceToLink.filter((e) => !!e.checked);

        const payload = {
          resources: checkedResources.map((e) => e.id)
        };

        await this.$http
          .post(`/api/${this.prefix}/myconfig/workcenter/${this.currentId}/resource/add`, payload)
          .then((response) => {
            this.unCheckAllResource();
            checkedResources.map((e) => this.resourceList.push(e));
            this.UPDATE_RESOURCE_LIST(this.resourceList);
            this.getResourceToLink();
            this.showToast(
              this.$t('IntegrationPage.success'),
              'SmileIcon',
              this.$t('StructurePage.LinkedResources'),
              'success'
            );
            this.$emit('updateDragList');
          })
          .catch((error) => {
            error.response.data.errors.map((e) => {
              this.showToast(this.$t('Error'), 'FrownIcon', e.message, 'danger');
            });
            this.getResourceToLink();
          })
          .finally(() => this.UPDATE_FLAG_SHOW_OVERLAY(false));
      }
    },
    computed: {
      ...mapState('structures', {
        isModalLinkResourceOpen: 'isModalLinkResourceOpen',
        resourceToLink: 'resourceToLink',
        resourceList: 'resourceList',
        rolesList: 'rolesList'
      })
    },
    watch: {
      isModalLinkResourceOpen(v) {
        this.isOpen = v;
        this.getAllFunctionsFilter();
        if (v) {
          this.getResourceToLink();
        } else {
          this.selectedFilter = 'DepartmentPage.All';
        }
      },
      selectedFilter(v) {
        this.filter.textFilter = [];
        this.unCheckAllResource();
        this.updateFilterByFunction(v);
      },
      filteredList(newValue, oldValue) {
        const previousChecked = oldValue.filter(e => e.checked);
        previousChecked.map(e => {
          newValue.map(f => {
            if (f.id === e.id) f.checked = true;
          })
        })
      }
    }
  };
</script>
<style lang="scss">
  .structure-sidebar-link-resource-modal {
    .b-sidebar.b-sidebar-right {
      transition: all 0.5s ease-out;
      border-left: 1px solid #cfc4be;
      @media (max-width: 814px) {
        width: 50vw !important;
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: #cfc4be;
    }

    .b-sidebar {
      height: 100% !important;

      .b-sidebar-header {
        display: none;
      }

      .b-sidebar-body {
        padding: 16px;
        overflow: hidden;

        .text-block {
          .title {
            color: #4c4541;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
            padding-bottom: 8px;
          }

          .subtitle {
            color: #998f8a;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            padding-bottom: 16px;
          }
        }

        .search-block {
          .search-input {
            display: flex;
            align-items: center;
            padding: 16px 0 8px;

            .div-custom-input {
              display: flex;
              align-items: center;
              border-radius: 4px;
              width: 100%;
              overflow: hidden;
              border: 1px solid #cfc4be;
              .icon-block {
                display: flex;
                align-items: center;
                padding-left: 14px;
                height: 14px;
                .search-icon {
                  width: 14px;
                  height: 14px;
                  fill: #cfc4be;
                }
              }
              .input-search-structures {
                width: 100% !important;
                border: none;
                overflow: hidden;

                &.focus {
                  box-shadow: none;
                }
                &.b-form-tags {
                  padding: 0 !important;
                  display: flex;
                  align-items: center !important;
                  .b-form-tags-list {
                    width: 100% !important;
                    margin-left: 14px !important;
                    margin-top: 0;
                    height: 100% !important;
                    flex-wrap: nowrap !important;
                    display: flex !important;
                    align-items: center !important;
                    overflow: scroll;
                    overflow-y: hidden;
                    cursor: grabbing;
                    cursor: -webkit-grabbing;

                    &::-webkit-scrollbar {
                      display: none !important;
                    }
                    .b-form-tags-field {
                      margin: 9px 0;
                    }
                  }
                }

                .b-form-tags-button {
                  display: none;
                }

                .b-form-tag {
                  background-color: #974900;
                  margin-right: 0.6rem;
                  padding: 2px 8px !important;
                  font-size: 13px;
                }
              }
            }
          }
          .label-title {
            color: #4c4541;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
            padding-bottom: 8px;
          }
        }

        .action-block {
          display: flex;
          justify-content: space-between;
          padding: 8px 0;

          .select-all {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 0 16px;

            .text {
              color: #4c4541;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
            }
          }

          .function-filter .filter {
            color: #974900;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.15px;
          }

          .function-filter {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #4c4541;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;

            .filter {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 4px 10px;
              border-radius: 5px;
              cursor: pointer;

              .arrow-down {
                width: 12px;
                height: 12px;
              }

              &:hover {
                background: #ffede2;
              }

              &:active {
                background: #ffdbc4;
              }
            }
          }
        }

        .resource-block {
          padding-bottom: 16px;
          overflow: auto;
          height: calc(100vh - 281px);

          &:has(.block-no-results) {
            overflow-y: hidden;
          }

          .block-no-results {
            height: 100%;
            padding: 16px 0;
            .no-results {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 16px;
              border-radius: 6px;
              border: 1px solid #cfc4be;

              svg {
                width: 16px;
                height: 16px;
                fill: #998f8a;
              }

              .text {
                margin: 0;
                color: #998f8a;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
              }

              @media (max-width: 480px) {
                svg {
                  width: 12px;
                  height: 12px;
                }
                .text {
                  font-size: 12px;
                  line-height: 16px;
                }
              }
            }
          }

          .user-info-block {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 7.5px 16px;
            overflow: hidden;
            border-bottom: 1px solid #eee;
            background: #fff;

            .picture img {
              width: 40px;
              border-radius: 50%;

              @media (max-width: 480px) {
                width: 30px;
              }
            }

            .check-resource {
              display: flex;

              .checkbox-container {
                margin: 0;
              }
            }

            .text-block {
              overflow: hidden;
              .resource-name {
                color: #4c4541;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                white-space: nowrap;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .resource-code {
                color: #998f8a;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
              }
            }
          }

          .roles-block {
            display: flex;
            justify-content: flex-end;
            gap: 8px;
            padding-right: 16px;
            margin-left: auto;
            .role-tag {
              white-space: nowrap;
              border-radius: 4px;
              background: #eceff1;
              padding-inline: 9px;
              color: #607d8b;
              text-align: center;
              font-size: 12px;
              font-weight: 600;
              line-height: 16px;
            }
          }

          &::-webkit-scrollbar {
            width: 7px;
          }

          &::-webkit-scrollbar-track {
            background: #fff;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #cfc4be;
            border-radius: 9px;
          }
        }

        .checkbox-container {
          display: flex;
          background-color: #fff;
          border: 1px solid #cfc4be;
          border-radius: 3px;
          cursor: pointer;
          width: 18px;
          height: 18px;

          .icon-checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2px;
            svg {
              width: 12px;
              height: 12px;
            }
          }
          &.checked {
            background-color: #974900;
            display: block;
          }
        }

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #cfc4be;
          border-radius: 9px;
        }
      }
    }

    .footer-sidebar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;
      border-top: 1px solid #cfc4be;
      background: #fff;

      .btn-footer {
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;

        &:hover {
          box-shadow: none;
        }

        &.btn-reset {
          background-color: transparent !important;
          color: #974900 !important;
          font-size: 12px !important;
          border: 1px solid transparent !important;
          padding: 4px 14px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-cancel {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          margin-right: 8px;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-save {
          border: 1px solid #974900 !important;
          background-color: #974900 !important;
          color: #ffff !important;
          padding: 8px 21px;

          &:hover {
            background-color: #9f5714 !important;
          }

          &:active {
            background-color: #a45f1f !important;
          }
        }
      }
    }
  }
</style>
